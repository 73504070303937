<template>
  <nav id="sidebar">
    <div class="sidebar-content">
      <div class="content-header justify-content-lg-center">
        <div>
          <a class="link-fx fw-bold tracking-wide mx-auto" href="#">
            <span class="smini-hidden">
              <i class="fa fa-fire text-primary"></i>
              <span class="fs-5 text-dual">Emo-Tool</span>
            </span>
          </a>
        </div>
        <div>
          <button
            class="btn btn-sm btn-alt-danger d-lg-none"
            data-action="sidebar_close"
            data-toggle="layout"
            type="button"
          >
            <i class="fa fa-fw fa-times"></i>
          </button>
        </div>
      </div>
    </div>
    <div class="js-sidebar-scroll">
      <div class="content-side content-side-user px-0 py-0">
        <div class="smini-hidden text-center mx-auto">
          <a class="img-link" href="/user">
            <img
              alt=""
              class="img-avatar"
              :src="'//q2.qlogo.cn/g?b=qq&nk=' + user.qq + '&s=100'"
            />
          </a>
          <ul class="list-inline mt-3 mb-0">
            <li class="list-inline-item">
              <a
                class="link-fx text-dual fs-sm fw-semibold text-uppercase"
                href="/user"
                >{{ user.nickname }}
              </a>
            </li>
            <li class="list-inline-item">
              <a
                class="link-fx text-dual"
                data-action="dark_mode_toggle"
                data-toggle="layout"
                href="javascript:void(0)"
              >
                <i class="fa fa-burn"></i>
              </a>
            </li>
            <li class="list-inline-item">
              <a href="javascript:" @click="logout()" class="link-fx text-dual">
                <i class="fa fa-sign-out-alt"></i>
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div class="content-side content-side-full" id="nav-main">
        <ul class="nav-main">
          <li class="nav-main-heading">控制台</li>

          <li
            class="nav-main-item"
            v-for="item in console_menus"
            :key="item.id"
          >
            <router-link class="nav-main-link" :to="item.path">
              <i :class="item.icon"></i>
              <span class="nav-main-link-name">{{ item.title }}</span>
            </router-link>
          </li>

          <li class="nav-main-item">
            <router-link class="nav-main-link" to="/home">
              <i class="nav-main-link-icon fa fa-house-chimney-user"></i>
              <span class="nav-main-link-name">用户中心</span>
            </router-link>
          </li>
          <li class="nav-main-heading">功能</li>

          <li
            class="nav-main-item"
            v-for="item in Function_menus"
            :key="item.id"
          >
            <a
              aria-expanded="false"
              aria-haspopup="true"
              class="nav-main-link nav-main-link-submenu"
              data-toggle="submenu"
              href="#"
            >
              <i :class="'nav-main-link-icon fa ' + item.icon + ''"></i>

              <span class="nav-main-link-name">{{ item.title }}</span>
            </a>
            <ul
              class="nav-main-submenu"
              v-for="subItem in item.children"
              :key="subItem.id"
            >
              <li class="nav-main-item" v-if="subItem.type !== 3">
                <router-link class="nav-main-link" :to="subItem.path">
                  <span class="nav-main-link-name">{{ subItem.title }}</span>
                </router-link>
              </li>
            </ul>
          </li>

          <li class="nav-main-heading text-center">- 更多功能敬请期待 -</li>
          <li class="nav-main-heading">其他</li>
          <!-- <li class="nav-main-item">
            <a
              aria-expanded="false"
              aria-haspopup="true"
              class="nav-main-link nav-main-link-submenu"
              data-toggle="submenu"
              href="#"
            >
              <i class="nav-main-link-icon fa fa-cart-shopping"></i>
              <span class="nav-main-link-name">自助商店</span>
            </a>
            <ul class="nav-main-submenu">
              <li class="nav-main-item">
                <router-link class="nav-main-link" to="/shop/quota">
                  <span class="nav-main-link-name">购买配额</span>
                </router-link>
              </li>

              <li class="nav-main-item">
                <router-link class="nav-main-link" to="/shop/ym">
                  <span class="nav-main-link-name">购买宥马</span>
                </router-link>
              </li>

              <li class="nav-main-item">
                <router-link class="nav-main-link" to="/shop/money">
                  <span class="nav-main-link-name">充值余额</span>
                </router-link>
              </li>
            </ul>
          </li> -->
          <li class="nav-main-item">
            <router-link class="nav-main-link" to="/faq">
              <i class="nav-main-link-icon fa fa-circle-info"></i>
              <span class="nav-main-link-name">帮助中心</span>
            </router-link>
          </li>
          <li class="nav-main-item">
            <router-link class="nav-main-link" to="/user">
              <i class="nav-main-link-icon fa fa-user"></i>
              <span class="nav-main-link-name">个人资料</span>
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import { logout } from "@/api/user";

export default {
  name: "Aside",
  props: {
    user: Object,
  },

  data() {
    return {
      console_menus: [],
      Function_menus: [],
    };
  },

  created() {
    let menus = localStorage.getItem("menus")
      ? JSON.parse(localStorage.getItem("menus"))
      : [];
    for (const i in menus) {
      if (menus[i].type === 1) {
        this.console_menus.push(menus[i]);
      } else {
        this.Function_menus.push(menus[i]);
      }
    }
  },
  methods: {
    logout() {
      this.$confirm("你确定退出登录吗?", "Warning", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        logout()
          .then(() => {
            this.$message({
              type: "success",
              message: "注销成功",
            });
            setTimeout(() => {
              this.$store.commit("logout");
            }, 1000);
          })
          .catch(() => {
            this.$message({
              type: "error",
              message: "注销失败",
            });
          });
      });
    },
  },
};
</script>

<style></style>
